<template>
  <div>
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset
        :loading="loading"
        :model.sync="model"
        @search="
          _clearPagination();
          getList();
        "
      ></search-reset>
    </l-toggle>
    <p class="tip">
      交易总笔数：
      <span class="green">{{ sum.tradeSum }}</span
      >， 总支付金额：
      <span class="green">{{ sum.paymentAmountSum }}</span>
      元， 总寄存费收入：
      <span class="green">{{ sum.consignFeeSum }}</span>
      元， 总续期费收入：
      <span class="green">{{ sum.renewalFeeSum }}</span>
      元， 总处置费收益：
      <span class="green">{{ sum.disposalAmountSum }}</span>
      元
    </p>
    <dynamic-table
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :pagination.sync="pagination"
      :selections.sync="selections"
      :tools="tools"
      @update="getList"
    ></dynamic-table>
  </div>
</template>

<script>
import LToggle from '../../components/LToggle.vue';
import DynamicForm from '../../components/DynamicForm.vue';
import SearchReset from '../../components/SearchReset.vue';
import DynamicTable from '../../components/DynamicTable.vue';
import baseMixin from '../../mixins/base-mixin';
import api from '../../api/OrderManagement';
import { mapState } from 'vuex';

export default {
  name: '',
  components: {
    LToggle,
    DynamicForm,
    SearchReset,
    DynamicTable,
  },
  mixins: [baseMixin],
  data() {
    return {
      model: {},
      sum: {
        tradeSum: '',
        paymentAmountSum: '',
        consignFeeSum: '',
        renewalFeeSum: '',
        disposalAmountSum: '',
      },
      columns: [
        {
          type: 'selection',
          fixed: 'left',
        },
        {
          label: '订单编号',
          prop: 'orderNumber',
          minWidth: '70',
        },
        {
          label: '订单标题',
          prop: 'orderTitle',
          minWidth: '110',
        },
        {
          label: '客户姓名',
          prop: 'userName',
          minWidth: '40',
        },
        {
          label: '客户联系方式',
          prop: 'userPhone',
          minWidth: '40',
        },
        {
          label: '订单开始时间',
          prop: 'orderStartTime',
          minWidth: '80',
        },
        {
          label: '创建人',
          prop: 'creatorName',
          minWidth: '40',
        },
        {
          label: '检测师',
          prop: 'detectionName',
          minWidth: '50',
        },
        {
          label: '当前状态',
          prop: 'orderStatusName',
          minWidth: '80',
        },
        {
          label: '估值金额',
          prop: 'newestAssessmentPrice',
          minWidth: '40',
        },
        {
          label: '支付金额',
          prop: 'paymentAmount',
        },
        {
          label: '订单更新时间',
          prop: 'updateTime',
          minWidth: '80',
        },
        {
          label: '操作',
          prop: '_action',
          fixed: 'right',
          minWidth: '80',
          actions: [
            {
              title: '查看',
              type: 'text',
              icon: 'el-icon-view',
              id: 'see',
              condition: '!!row.id',
            },
          ],
        },
      ],
      tableData: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selections: [],
      tools: [],
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
    orderStatusOptions() {
      let options = this.filterDictionary('0108');
      const { orderPhase } = this.model;
      if (orderPhase) {
        options = options.filter(({ value }) => `${value}`.startsWith(`${orderPhase}`));
      }
      return options;
    },
    formItems() {
      return [
        {
          label: '订单编号',
          type: 'text',
          name: 'orderNumber',
          placeholder: '请输入订单编号',
        },
        {
          label: '客户姓名',
          type: 'text',
          name: 'userName',
          placeholder: '请输入客户姓名',
        },
        {
          label: '客户联系方式',
          type: 'text',
          name: 'userPhone',
          placeholder: '请输入客户联系方式',
        },
        {
          label: '订单开始时间',
          type: 'daterange',
          starName: 'orderStartTimeBegin',
          starPlaceholder: '请输入开始日期',
          endName: 'orderStartTimeEnd',
          endPlaceholder: '请输入结束日期',
        },
        {
          label: '订单类型',
          type: 'select',
          name: 'orderPhase',
          placeholder: '请输入订单类型',
          options: this.filterDictionary('0107'),
          change: () => {
            const target = { ...this.model };
            Object.assign(target, {
              orderStatus: '',
            });
            this.model = target;
          },
        },
        {
          label: '订单状态',
          type: 'select',
          name: 'orderStatus',
          placeholder: '请输入订单状态',
          options: this.orderStatusOptions,
        },
      ];
    },
  },
  created() {},
  methods: {
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
          isPartner: '1',
        };
        api
          .getOrderInfoList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            this.getSum();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    getSum() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model } = this;
        const ext = {
          ...model,
          isPartner: '1',
        };
        api
          .getSum(ext)
          .then(res => {
            this.sum = res.result;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss">
.tip {
  padding: 0 15px;
  background: #ffffff;
}
.green {
  font-weight: bold;
  color: #009966;
}
</style>
